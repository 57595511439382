
import { defineComponent } from 'vue'
import Rock from '@/types/Rock'
import { generateRockPlacement, makeRock } from '@/lib'
import { ROCK_DATA, TARGET_ROCK_COUNT } from '@/constants'
import { POLISH_CYCLES } from '@/types/POLISH_CYCLES'

declare interface RockPickerData {
  dialog: boolean,
  selectedRock: null|Rock,
  rockWidth: number,
  rockHeight: number,
  xCoordinates: { [key: string]: number },
  yCoordinates: { [key: string]: number },
  rockRotation: { [key: string]: number },
  alert: boolean,
}

export default defineComponent({
  name: 'RockPicker',
  methods: {
    openDialog (rock: Rock) {
      this.selectedRock = rock
      this.dialog = true
    },
    rockChosen (rock: Rock) {
      this.$store.dispatch('moveRocks', { sourceList: 'outside', rocks: [rock], destList: 'tumbling' }).then(() => {
        if (this.tumbling.length >= TARGET_ROCK_COUNT) {
          this.$router.push({ name: 'Wash' })
        }
        this.dialog = false
      })
    },
    assetForRock (rock: Rock) {
      return ROCK_DATA[rock.type].assets[POLISH_CYCLES.NONE]
    },
    bioForRock (rock: Rock) {
      return ROCK_DATA[rock.type].bio
    },
    chuckRock (rock: Rock) {
      this.dialog = false
      this.selectedRock = null
      this.$store.commit('removeRocks', { rockList: 'outside', rocks: [rock] })
    },
    calculateRockPlacement () {
      const rockSizeAdjustment = window.innerWidth <= 700 ? 0.3 : 0.2
      const verticalBoundaryReductionFactor = 0.39
      const horizontalBoundaryReductionFactor = 0.24

      this.rockWidth = (rockSizeAdjustment) * window.innerWidth
      this.xCoordinates = generateRockPlacement(this.rocks, 0, window.innerWidth, horizontalBoundaryReductionFactor)
      this.yCoordinates = generateRockPlacement(this.rocks, 72, window.innerHeight, verticalBoundaryReductionFactor)
      this.rockRotation = generateRockPlacement(this.rocks, -365, 365, 0)
    }
  },
  watch: {
    'rocks.length': {
      immediate: true,
      handler (length: number) {
        if (length) {
          return
        }
        if (this.rocks.length === 0) {
          const rocks = [makeRock(), makeRock(), makeRock(), makeRock(), makeRock()]
          this.$store.commit('addRocks', { rockList: 'outside', rocks: rocks })
          this.calculateRockPlacement()
        }
      }
    }
  },
  created () {
    if (this.tumbling.length >= TARGET_ROCK_COUNT) {
      if (this.$store.state.washed) {
        this.$router.push({ name: 'Tumbler' })
      } else {
        this.$router.push({ name: 'Wash' })
      }
    }
  },
  computed: {
    // mapState doesn't seem to work with TypeScript? Would have made this more succinct...
    rocks () { return this.$store.state.rockLists.outside },
    tumbling () { return this.$store.state.rockLists.tumbling },
    running () { return this.$store.state.running },
    remainingRocks () { return TARGET_ROCK_COUNT - this.$store.state.rockLists.tumbling.length }
  },
  data (): RockPickerData {
    return {
      dialog: false,
      selectedRock: null,
      rockWidth: 0,
      rockHeight: 0,
      xCoordinates: {},
      yCoordinates: {},
      rockRotation: {},
      alert: true
    }
  },
  mounted () {
    this.calculateRockPlacement()
  }
})
